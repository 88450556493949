import React from 'react'
import Topbar from './components/topBar/TopBar'
import './AdminDashboard.scss'
import { useParams } from 'react-router-dom'
import Sidebar from './components/sideBar/Sidebar'
import Users from './components/Users/Users'
import VerifyUser from './components/AdminModals/VerifyUser'
import { useGlobalContext } from '../../utilities/Context/Context'
import User from './components/user/User'
import CreditUser from './components/AdminModals/CreditUser'
import Receipt from '../user/components/userModals/Receipt'
import Transactions from './components/transactions/Transactions'
import Transaction from './components/transaction/Transaction'
import AdminLoans from './components/loans/AdminLoans'
import RestrictUser from './components/AdminModals/RestrictUser'
import DebitUser from './components/AdminModals/DebitUser'

function AdminDashboard({ notify }) {
  const {
    verifyData,
    showCreditUser,
    showDebitUser,
    receiptData,
    restrictUser,
  } = useGlobalContext()
  const dashboardPage = () => {
    switch (page) {
      case 'users':
        return <Users notify={notify} />
      case 'user':
        return <User notify={notify} />
      case 'transactions':
        return <Transactions notify={notify} />
      case 'transaction':
        return <Transaction notify={notify} />
      case 'loans':
        return <AdminLoans notify={notify} />
      default:
        return <h1>Page not found</h1>
    }
  }
  const { page } = useParams()
  return (
    <div className='admin-dashboard'>
      <Topbar />
      {verifyData && <VerifyUser notify={notify} />}
      {showCreditUser && <CreditUser notify={notify} />}
      {showDebitUser && <DebitUser notify={notify} />}
      {restrictUser !== null && <RestrictUser notify={notify} />}
      {receiptData && <Receipt notify={notify} />}
      <div className='main_body'>
        <Sidebar page={page} />
        <div className='main_container'>{dashboardPage()}</div>
      </div>
    </div>
  )
}

export default AdminDashboard
